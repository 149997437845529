@import '../../../../assets/style/colors.scss';
@import '../../../../assets/style/spacing.scss';

.overallContainer {
  padding: 0rem 1.5rem;
}

.noteModal {
  width: 95%;
  padding: $space-lg 0;
  position: relative;

  > h1 {
    position: absolute;
    width: 100%;
    text-align: center;
    top: 0;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  > p {
    margin-top: $space-lg;
  }

  > button {
    margin-top: $space-lg;
  }
}
