@import '../../../../assets/style/mixins.scss';

.leaderBoards {
  display: flex;
  flex-direction: column;
  min-height: 0px; // this allows for flex scrollcontainer to function
}

.leaderBoardsBanner {
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 1fr 1fr 1fr;
  grid-template-areas: 'rank2 rank1 rank3';
  gap: 0.5rem;
  padding: 1.5rem;
  background-image: linear-gradient(to bottom, $primary-color, transparent 80%);
}

.topThreeBadge {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 0.5rem;
  margin-top: auto;
  gap: 0.5rem;
  flex: 33%;
  overflow: hidden;
  white-space: wrap;

  &__iconWrapper {
    position: relative;
  }

  &__rank {
    position: absolute;
    left: 50%;
    top: 45%;
    color: $black;
    transform: translate(-50%, -50%);
    font-size: 1rem;
    font-family: 'CanaroBold';
  }

  & > .label {
    font-size: 10px;
  }

  &__userName {
    font-size: 0.65rem;
    text-align: center;
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    max-width: 100%;
    width: 100%;
  }

  &[data-rank='1'] {
    grid-area: rank1;
    gap: 1rem;
    padding: 1rem 0.5rem 2rem;

    .topThreeBadge__userName {
      font-size: 0.75rem;
    }

    .label {
      font-size: 12px;
      color: $black;
      background-color: $nr-color-yellow;
      font-family: 'CanaroBold';
    }
  }
}

@mixin rankMixin {
  display: flex;
  align-items: center;
  gap: 1rem;

  & > p:nth-child(1) {
    width: 2rem;
    text-align: center;
  }

  & > p:nth-child(2) {
    flex-grow: 1;
  }

  & > p:nth-child(3) {
    width: 2.5rem;
    text-align: center;
  }
}

.ranks {
  display: flex;
  flex-direction: column;
  min-height: 0px; // this allows for flex scrollcontainer to function
}

.ranksHeader {
  background-color: $gray-dark;
  @include rankMixin;
  padding: 0.5rem 1.5rem;
}

.ranksList {
  flex-grow: 1;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 1rem;

  &[data-overlay='true'] {
    position: relative;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      left: 0;
      height: 100px;
      background-image: linear-gradient($black, transparent);
    }
  }
}

.singleRank {
  @include rankMixin;
  padding: 0.5rem;

  & > p:nth-child(3) {
    padding: 0.25rem 0;
  }

  &[data-me='true'] {
    background-color: $primary-color;
    border-radius: 1rem;

    & > p:nth-child(3) {
      background-color: $primary-dark;
      border-radius: 0.5rem;
    }
  }
}

.leaderBoardsNoPermission {
  height: 100%;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 2.5rem;

  &__text {
    text-align: center;

    display: grid;
    gap: 0.5rem;
  }
}
