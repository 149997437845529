.single-buddy {
  display: flex;
  padding: 0.5rem;
  height: 1.5rem;

  &__image {
    height: 1.5rem;
    padding: 0 0.25rem 0 0;
  }
}
