$primary-color: #00c0ba;
$primary-light: #99e6e3;
$primary-dark: #006663;

$secondary: #fb7558;
$secondary-light: #fcac9a;

$nr-color-blue: #36b2f8;
$nr-color-yellow: #ffbd2c;

$black: #182222;
$black-light: #464e4e;

$gray-dark: #282e2e;
$gray-light: #b5b5b5;

$white: #fff;

$shadow: 0px 0px 7px 5px rgba(0, 0, 0, 0.3);

$colors: (
  primary: (
    base: $primary-color,
    light: $primary-light,
    dark: $primary-dark,
  ),
  secondary: (
    base: $secondary,
    light: $secondary-light,
  ),
  black: (
    base: $black,
    light: $black-light,
  ),
  gray: (
    base: $gray-dark,
    light: $gray-light,
  ),
  white: (
    base: white,
  ),
);

@function clr($base, $shade: base) {
  $color: map-get(map-get($colors, $base), $shade);
  @return $color;
}
