@import '../../../assets/style/colors.scss';

select {
  font-size: 0.75rem;
  padding: 0 0 0.5rem 0;
  font-family: 'CanaroBook';
  font-weight: bold;
  color: $gray-light;
  border: 0;
  margin-left: -0.25rem;
  border-bottom: 2px solid $gray-light;
  background: transparent;
  width: 100%;

  > option {
    display: inline-block;
    padding: 0 0 0.5rem 0;
    font-size: 0.75rem;
    font-family: 'CanaroBook';
    font-weight: bold;
    color: $gray-light;
    border: 0;
    border-bottom: 2px solid $gray-light;
    background: $gray-dark;

    &[value=''][disabled] {
      display: none;
    }
  }
}
