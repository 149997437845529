@import '../../../assets/style/colors.scss';

.header {
  background-color: black;
  padding: 1rem 2rem;
  flex-grow: 0;
  flex-shrink: 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__logo {
    flex: 1;
  }

  &__route {
    display: flex;
    justify-content: flex-start;
    flex: 5;
  }

  &__profile {
    flex: 1;
  }

  &__notification {
    flex: 1;
  }

  &__text {
    margin-left: 0.5rem;
  }
}
