@import '../../../assets/style/colors.scss';

.single-question {
  font-size: 0.75rem;
  padding: 1rem 0 0.25rem 0;
  font-weight: bold;
  color: $gray-light;
  border: 0;
  border-bottom: 2px solid $gray-light;
  width: 100%;
  display: flex;
  justify-content: space-between;
}
