@import '../../../assets/style/mixins.scss';

.resetPassword {
  padding: 1rem;

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;

    & > * {
      margin: 0;
    }
  }

  &__body {
    padding: 1rem;

    & > h2 {
      text-align: center;
      margin-top: 2rem;
      margin-bottom: 6rem;
    }
  }

  &__card {
    @include card;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  & .nextroundBtnLg {
    width: fit-content;
    padding-inline: 2rem;
    margin-top: 2rem;
    margin-bottom: 2rem;
  }

  &__error,
  &__help {
    margin-bottom: 2rem;
  }

  &__error {
    color: $secondary;
    text-align: center;
  }

  &__help {
    text-align: center;

    & > a {
      text-decoration: underline;
    }
  }
}
