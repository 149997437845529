@import '../../../assets/style/colors.scss';

.moreSubject {
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0 2rem;
  margin-bottom: 0.4rem;
  background-color: $gray-dark;

  &__text {
    padding-left: 1.5rem;
    flex: 5;
  }
}
