@import '../src/assets/style/index.scss';

.nextround {
  width: 100%;
  height: 100%;
  background-color: clr(black);
  position: absolute;
  display: flex;
  flex-direction: column;
  .component {
    padding: 1rem;
  }
}

.component-container {
  display: flex;
  flex-direction: column;
  overflow-y: scroll;
  flex-grow: 1;
}

.color {
  padding: 2rem 0;
  border: 2px solid white;
  text-align: center;
  &--primary {
    background-color: clr(primary);
  }
  &--primary-light {
    background-color: clr(primary, light);
  }
  &--primary-dark {
    background-color: clr(primary, dark);
  }
  &--secondary {
    background-color: clr(secondary);
  }
  &--secondary-light {
    background-color: clr(secondary, light);
    color: black;
  }
  &--black {
    background-color: clr(black);
  }
  &--black-light {
    background-color: clr(black, light);
  }
  &--gray {
    background-color: clr(gray);
  }
  &--gray-light {
    background-color: clr(gray, light);
    color: black;
  }
  &--white {
    background-color: clr(white);
    color: black;
  }
}
