.icon {
  justify-content: center;
  margin: auto auto;

  img {
    width: 100%;
    height: 100%;
    align-self: center;
    fill: currentColor;
  }
}
