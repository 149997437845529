@import '../../../assets/style/colors.scss';

.profile {
  margin: 1rem 1.5rem;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 1rem;
  }

  &__follow {
    display: flex;
    text-align: center;

    &__left {
      margin-right: 0.75rem;
    }

    &__right {
      margin-left: 0.75rem;
    }

    &__buttons {
      display: flex;
      justify-content: space-between;
    }
  }

  &__vl {
    border-left: 2px solid black;
    height: 2.5rem;
  }
}
