@font-face {
  font-family: 'CanaroBold';
  src: url('./../fonts/canaro/canaro-bold.eot'); /* IE9*/
  src: url('./../fonts/canaro/canaro-bold.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/canaro/canaro-bold.woff2') format('woff2'),
    /* chrome、firefox */ url('./../fonts/canaro/canaro-bold.woff')
      format('woff'),
    /* chrome、firefox */ url('./../fonts/canaro/canaro-bold.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./../fonts/canaro/canaro-bold.svg#Canaro W00 Bold') format('svg'); /* iOS 4.1- */
}

@font-face {
  font-family: 'CanaroBook';
  src: url('./../fonts/canaro/CanaroBook.eot'); /* IE9*/
  src: url('./../fonts/canaro/CanaroBook.eot?#iefix')
      format('embedded-opentype'),
    /* IE6-IE8 */ url('./../fonts/canaro/CanaroBook.woff2') format('woff2'),
    /* chrome、firefox */ url('./../fonts/canaro/CanaroBook.woff')
      format('woff'),
    /* chrome、firefox */ url('./../fonts/canaro/CanaroBook.ttf')
      format('truetype'),
    /* chrome、firefox、opera、Safari, Android, iOS 4.2+*/
      url('./../fonts/canaro/CanaroBook.svg#Canaro W00 Book') format('svg'); /* iOS 4.1- */
}

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  font-family: 'CanaroBold';
  font-weight: normal;

  &.light {
    font-family: 'CanaroBook';
  }
}

h1 {
  font-size: 2rem;
  line-height: 2.75rem;
}

h2 {
  font-size: 1.5rem;
}

h3,
h4 {
  font-size: 0.85rem;
  font-weight: bold;
}

h5,
h6 {
  font-size: 0.75rem;
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
  font-weight: bold;
  color: $primary-color;
}

p {
  font-size: 0.75rem;
  margin: 0;
  font-family: 'CanaroBook';

  &.small {
    font-size: 0.65rem;
  }

  &.large {
    font-size: 0.85rem;
  }

  &.bold {
    font-family: 'CanaroBold';
  }

  &.dark {
    color: $gray-light;
  }
}
