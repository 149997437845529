.workoutHeader {
  margin: 1rem 2rem 0.75rem 2rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1rem;

  & > .icon {
    margin: 0;
  }
}
