@import '../../../assets/style/colors.scss';

.new-workout {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-content: flex-start;
  top: 0;

  &-buttons {
    display: flex;
    flex-direction: row;
    width: 100%;
  }

  &-info {
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
  }

  &-header {
    color: $primary-color;
    font-size: 1.75em;
  }

  &-data {
    margin-top: 1rem;
    width: 100%;
    display: flex;
    justify-content: space-between;

    &-col {
      display: flex;
      flex-direction: column;

      &-2 {
        margin-right: 1.5rem;
        text-align: center;
      }
    }

    &-row {
      margin: 0.5rem 0 0.25rem 0;
    }
  }

  &-image {
    position: absolute;
    top: 12rem;
  }

  &-tip {
    width: 70%;
    bottom: 5rem;
    text-align: center;
    margin-top: 7rem;
  }
}
