@import '../../../assets/style/colors.scss';
@import '../../../assets/style/spacing.scss';

.setupProfile {
  display: flex;
  flex-direction: column;
  height: 100%;
  position: absolute;
  background-color: $black;

  &__header {
    background-color: black;
    padding: $space-md $space-lg;
    height: $space-md;
  }

  &__content {
    text-align: center;
    padding: $space-lg;

    > * {
      margin: $space-sm auto;
    }
  }
  &__pw {
    margin-top: $space-md;
    text-align: left;
  }

  &__pw-header {
    margin-top: $space-md * 0.75;
  }

  &__error {
    color: $secondary;
    text-align: center;
  }
}
