@import '../../../assets/style/colors.scss';

.single-notification {
  margin: 0.75rem 0;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__name {
    &--normal {
      font-weight: normal;
    }

    &--bold {
      font-weight: bold;
    }
  }

  &__toggle {
    height: 1.25rem;
    width: 2.5rem;
    border-radius: 15px;
    display: flex;

    &--on {
      background-color: $primary-color;
      justify-content: flex-end;
    }

    &--off {
      background-color: $gray-light;
      justify-content: flex-start;
    }
  }

  &__toggle-button {
    height: 1.25rem;
    width: 1.25rem;
    background-color: white;
    border-radius: 15px;
  }
}
