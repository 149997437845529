@import '../../../assets/style/colors.scss';
@import '../../../assets/style/mixins.scss';

.profileCard {
  @include card;
  padding: 1rem;

  &__top,
  &__bottom {
    display: grid;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(3, 1fr);
    text-align: center;
    justify-self: center;
    align-items: center;

    > * {
      padding: 0.5rem;
    }
  }

  &__bottom {
    grid-template-columns: repeat(2, 1fr);
  }
}
