@import '../../../assets/style/colors.scss';

.loader {
  width: 2.5rem;
  height: 2.5rem;
  border: 5px solid $primary-color;
  border-bottom-color: transparent;
  border-radius: 50%;
  display: inline-block;
  margin: 2rem auto;
  box-sizing: border-box;
  animation: rotation 1s linear infinite;
}

@keyframes rotation {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}
