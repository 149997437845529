@import '../../../assets/style/colors.scss';

.welcome {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../../assets/images/background_home.jpg');
  background-repeat: no-repeat;
  background-size: 100%;
  position: absolute;
  width: 100%;
  height: 100%;
  background-color: $black;

  &__container {
    height: calc(100% - 7rem);
    justify-content: space-between;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 6rem 2.5rem 1rem 2.5rem;
  }

  &__header {
    display: flex;
    flex-direction: column;
    align-items: center;

    &-sub {
      font-weight: bold;
      margin-top: 0.75rem;
    }
  }

  &__button-container {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 1rem;
  }

  &__discoverBtn {
    background-color: transparent;
  }

  &__nr-link {
    padding-top: 0.75rem;
  }
}

.login-button {
  color: white;
}

.discover-button {
  &__title {
    color: $primary-color;
    font-weight: bold;
  }

  &__sub {
    color: white;
  }
}
