@import '../../../../../assets/style/colors.scss';

.roundsGraph {
  &__wrapper {
    width: 100%;
    margin-top: 1rem;
    bottom: 0;
    display: flex;
    position: relative;
    height: 15rem;
  }

  &__graphContainer {
    overflow-x: scroll;
    scroll-behavior: smooth;
    display: flex;
    height: calc(100% - 2rem);
    width: 300px;
    position: relative;

    &::-webkit-scrollbar {
      display: none;
    }
  }

  &__singleRoundWrapper {
    position: relative;
    height: 100%;
    width: 300px;
  }

  &__roundSeperator {
    height: 100%;
    display: flex;
    flex-direction: column;
    position: absolute;
    bottom: 0;

    &__verticalLine {
      border-left: 2px solid white;
      height: calc(100% - 3rem);
      z-index: 1;
      width: 2px;
    }
  }

  &__arrowRight {
    position: sticky;
    z-index: 10;
    right: 0;
    top: 0;
    width: 40px;
    height: calc(100% - 2rem);
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    background-color: $primary-dark;
    cursor: pointer;
    z-index: 1;

    &:hover {
      background-color: $primary-color;
    }

    &::after {
      content: '>';
      font-size: 2rem;
      flex: 0;
      width: 40px;
    }
  }

  &__y-axis {
    &__wrapper {
      position: sticky;

      margin-right: 1.5rem;
      height: calc(100% - 3.5rem);
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      min-width: 1rem;
    }

    &__tick {
      display: flex;
      position: relative;
      margin-bottom: 4px;
    }

    &__line {
      border-color: $black-light;
      position: absolute;
      left: 2rem;
      z-index: 5;
      width: 235px;
      height: 2px;
      z-index: 1;
    }

    &__avg {
      display: flex;
      position: absolute;

      & > div {
        position: relative;

        & > p {
          position: absolute;
          top: 50%;
          transform: translateY(-50%);
          left: 5px;
        }
      }
    }

    &__avgLine {
      background-color: white;
      margin: 0;
      position: absolute;
      left: 2rem;
      z-index: 5;
      width: 18rem;
      height: 1px;
      z-index: 1000;
    }
  }

  &__x-axis {
    &__wrapper {
      position: absolute;
      width: 300px;
      bottom: 10px;
      left: 0;
    }

    &__line {
      background-color: white;
      width: 100%;
      height: 1px;
    }

    &__ticksWrapper {
      display: flex;
      justify-content: space-between;
      width: 100%;
    }
  }
}
