@import '../../../assets/style/colors.scss';

.inputContainer {
  width: 100%;
  margin: 0.5rem 0;

  &--noSpace {
    margin-top: 0;
    width: 100%;
  }
}

input:focus,
textarea:focus,
select:focus {
  outline: none;
}

input[type='radio'] {
  display: none;
}

input[type='text'] {
  font-size: 0.75rem;
  padding: 0 0 0.5rem 0;
  font-family: 'CanaroBook';
  font-weight: bold;
  color: $gray-light;
  border: 0;
  border-bottom: 2px solid $gray-light;
  background: transparent;
  width: 100%;

  &::-webkit-input-placeholder {
    color: $gray-light;
  }
}

input[type='password'],
input[type='email'] {
  font-size: 0.75rem;
  padding: 0 0 0.5rem 0;
  font-family: 'CanaroBook';
  font-weight: bold;
  color: $gray-light;
  border: 0;
  border-bottom: 2px solid $gray-light;
  background: transparent;
  width: 100%;

  &::-webkit-input-placeholder {
    color: $gray-light;
  }
}

input[type='number'] {
  font-size: 0.75rem;
  padding: 0 0 0.5rem 0;
  font-family: 'CanaroBook';
  font-weight: bold;
  color: $gray-light;
  border: 0;
  border-bottom: 2px solid $gray-light;
  background: transparent;
  width: 100%;

  &::-webkit-input-placeholder {
    color: $gray-light;
  }
}
