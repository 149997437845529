@import '../../../assets/style/colors.scss';
@import '../../../assets/style/spacing.scss';

.activity-feed {
  &__selector {
    border: none;
    flex: 1 0 auto;
    justify-content: space-around;
    background-color: #000000;
    width: 100%;
    position: fixed;
  }

  &__workouts {
    margin: $space-lg;
    flex: 1 0 auto;
    padding-bottom: 1.5rem;
  }
}

.no-workouts {
  margin: 4rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__title {
    font-size: 1.5rem;
    font-weight: 400;
  }

  &__text {
    margin-top: 1.5rem;
    text-align: center;
    margin-bottom: 2rem;
    font-size: 1rem;
    display: inline;

    > span {
      color: $primary-color;
    }
  }
}

.inviteButton {
  color: #fff;
}
