@import '../../../../assets/style/mixins.scss';
@import '../../../../assets/style/spacing.scss';

.detailCard {
  @include card;
  padding: $space-md;

  &__row {
    display: flex;
    width: 100%;
    margin: $space-sm 0;

    &__icon {
      width: 10%;
    }

    &__key {
      width: 65%;
    }

    &__value {
      width: 25%;
    }
  }
}
