@import '../../../../assets/style/colors.scss';

.perRoundContainer {
  padding: 0rem 1.5rem;
}

.display-round {
  display: flex;
  justify-content: space-between;
  margin: 0.75rem 0rem;
  font-size: 0.75rem;
  padding: 0.4rem 0rem;
  width: 100%;
  border-bottom: 2px solid $black-light;
}

.temp-exercises {
  display: flex;
  justify-content: space-between;
}

.exercise-card {
  font-size: 0.75rem;
  background-color: $black-light;
  padding: 0.4rem;
  margin: 0.4rem 0;
  border-radius: 0.5rem;
  display: flex;
  justify-content: space-between;
  align-items: center;

  &__time {
    display: flex;
    align-items: center;

    > div {
      margin-right: 0.3rem;
    }
  }

  &__moves {
    display: flex;
    max-width: 70%;
    flex-wrap: wrap;
  }

  &__move {
    margin-right: 0.5rem;
  }
}
