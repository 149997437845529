@import '../../../assets/style/colors.scss';

.nextroundBtnLg {
  font-family: 'CanaroBook';
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0 auto;
  color: #fff;
  width: 100%;
  max-width: 18.75rem;
  height: 2.875rem;
  max-height: 2.875rem;
  padding: 0.5em;
  border-radius: 10px;

  &__primary {
    border: 2px solid $primary-color;
    background-color: $gray-dark;
  }

  &__secondary {
    border: 2px solid $gray-light;
    background-color: $gray-dark;
  }

  &__tertiary {
    border: 2px solid $primary-color;
    background-color: $primary-color;
  }
}
