@import '../../../assets/style/colors.scss';

.line {
  &--neutral {
    border-color: $black-light;
  }
  &--light {
    border-color: $gray-light;
  }
  &--dark {
    border-color: $gray-dark;
  }
}
