@import '../../../../assets/style/colors.scss';
@import '../../../../assets/style/spacing.scss';

.note {
  margin: $space-md 0;
  display: grid;
  grid-template-columns: 20% 80%;
  grid-template-areas:
    '. noteTitle'
    'noteIcon noteAdd';

  &__title {
    grid-area: noteTitle;
  }

  &__icon {
    grid-area: noteIcon;
  }

  &__add {
    grid-area: noteAdd;
  }
}
