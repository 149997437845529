@import '../../../assets/style/colors.scss';

.footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: darken(clr(black), 10%);
  padding: 1rem 2rem;
  flex-grow: 0;
  flex-shrink: 0;
}
