@import '../../../assets/style/colors.scss';
@import '../../../assets/style/spacing.scss';

.input-param {
  color: $gray-light;
  font-size: 0.65rem;
  margin-top: 0.5rem;
}

.editProfile {
  height: 100%;
  padding: $space-lg;

  &__info {
    display: flex;
    flex-direction: column;
    align-items: center;

    > p {
      margin-bottom: $space-md;
    }
  }

  &__error {
    color: $secondary;
  }

  &__success {
    text-align: center;
    margin: 1rem 0;
    color: $primary-color;
  }

  &__btn {
    margin-top: $space-lg;
  }

  &__fieldDescription {
    margin-top: $space-md;
  }

  &__inDevelopment {
    color: red;
    margin: 1rem auto 0;
    text-align: center;
  }
}
