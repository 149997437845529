@import '../../../assets/style/colors.scss';

.punchData {
  border-radius: 0.5rem;
  background-color: lighten($gray-dark, 10%);
  padding: 0.5rem;
  display: grid;
  grid-template-rows: repeat(2, 1.5rem);
  grid-template-columns: repeat(3, 1fr);
  text-align: center;
  justify-content: center;
  align-items: center;
}
