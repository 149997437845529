@import '../../../assets/style/spacing.scss';

.profile {
  margin: $space-md;
  height: 100%;
  display: flex;
  flex-direction: column;

  &__header {
    display: flex;
    justify-content: space-between;
    margin-bottom: $space-md;
  }

  &__btnWrapper {
    display: flex;
    gap: $space-lg;
    align-items: center;
    justify-content: center;
    padding: $space-md 0;

    & button {
      margin: 0;
    }
  }
}
