@import '../../../assets/style/colors.scss';
@import '../../../assets/style/fonts.scss';

.tabsPanel {
  display: flex;
  width: 100%;
  text-align: center;
}

.singleTab {
  flex: 1;
  position: relative;
  padding-bottom: 0.5rem;
  border-bottom: 1px solid $black-light;

  &[data-active='true'] {
    border-bottom: 3px solid $primary-color;
    font-family: 'CanaroBold';
  }

  &[data-new='true']:after {
    content: '';
    width: 8px;
    height: 8px;
    background-color: $nr-color-blue;
    border-radius: 50%;
    position: absolute;
    top: -8px;
    right: 12px;
  }
}
