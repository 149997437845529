@import '../../../assets/style/colors.scss';
@import '../../../assets/style/mixins.scss';
@import '../../../assets/style/spacing.scss';

.workoutCard {
  @include card;
  margin: $space-md 0;
  padding: 0.75rem;

  &__nameTime {
    padding: 0.4rem 0.2rem;
    display: flex;
    justify-content: space-between;
  }

  &__highlight {
    text-align: center;
    padding: $space-sm 0;
  }

  &__claps {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: $space-sm;

    > .icon {
      margin: 0;
    }
  }
}
