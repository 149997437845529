@import '../../../assets/style/colors.scss';

.tooltip {
  background-color: $black;
  left: 0;
  margin: 2rem;
  position: absolute;
  display: block;
  padding: 1rem 0;
}
