@import '../../../assets/style/colors.scss';
@import '../../../assets/style/spacing.scss';

.modal {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  z-index: 9999;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  overflow: hidden;

  &__wrapper {
    margin: $space-lg * 1.5 $space-md auto;
    display: flex;
    align-items: center;
    flex-direction: column;
    border-radius: 0.5rem;
    width: 100%;
    background-color: $black;
    padding: $space-md;
  }

  &__header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
  }

  &__content {
    width: 100%;
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    align-items: center;
    justify-content: center;
  }

  &--open {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  &--closed {
    display: none;
  }
}
