@import '../../../../assets/style/colors.scss';

@mixin star {
  height: 1.2rem;
  width: 1.2rem;
  border: 2px solid white;
  border-radius: 50%;
  display: inline-block;
  cursor: pointer;
}

.rating {
  width: 100%;
  margin: 0 auto;
  justify-content: center;
  align-items: center;
  text-align: center;

  &__card {
    margin: 0.5rem 0;
    padding: 0.75rem 2rem;
    display: flex;
    justify-content: space-around;
    max-width: 100%;
    max-height: 3rem;
    border-radius: 0.5rem;
    background-color: $primary-color;
    cursor: pointer;
  }
}

.star {
  @include star;

  &:hover {
    background-color: white;
  }

  &-filled {
    @include star;
    background-color: white;
  }
}
