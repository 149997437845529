@import '../../../assets/style/colors.scss';
@import '../../../assets/style/spacing.scss';

.signup {
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)),
    url('../../../assets/images/background_home.jpg');
  position: absolute;
  height: 100%;
  width: 100%;
  flex-direction: column;
  display: flex;

  &__header {
    padding: $space-lg;
    width: 100%;

    > .icon {
      margin-left: 0;
    }

    &__nextround {
      font-size: 1.75rem;
    }
  }

  &__container {
    height: 100%;
    background-color: black;
    padding: $space-lg;
  }

  &__input__wrapper {
    margin: $space-md auto;
  }

  &__input__container {
    margin-top: $space-lg;
  }

  &__error {
    color: $secondary;
    text-align: center;
  }
}
