@import './../../assets/style/colors';
@import './fonts.scss';

html,
body {
  margin: 0 auto;
  color: white;
  font-family: 'CanaroBook';
  background-color: clr(black);
}

a {
  text-decoration: none;
  padding: 0;
  color: white;
}
