@import '../../../../assets/style/spacing.scss';

.signup-popup {
  position: absolute;
  top: 5rem;
  height: 18rem;
  background-color: black;
  padding: $space-lg;
  text-align: center;

  &__head {
    display: flex;
    justify-content: space-between;

    > .icon {
      margin: 0;
    }
  }

  &__content {
    > * {
      padding: $space-md 0;
    }
  }
}
