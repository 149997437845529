@import '../../../assets/style/colors.scss';

.req-invite {
  height: 1.75rem;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  padding: 0.75rem;
  margin-bottom: 0.25rem;
  background-color: $gray-dark;

  &__text {
    height: 100%;
    padding-left: 1.5rem;
    flex: 5;
    display: flex;
    align-items: center;
  }

  &__number {
    margin-left: 1rem;
    background-color: $primary-color;
    height: 1.5rem;
    width: 1.5rem;
    border-radius: 0.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__icon {
    height: 100%;
    flex: 1;
    margin-right: 0.2rem;
    display: flex;
    align-items: center;
  }
}
