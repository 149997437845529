@import '../../../assets/style/colors.scss/';

.invite {
  margin: 1rem 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;

  &__user-wrapper {
    display: flex;
    margin-bottom: 1rem;
    align-items: center;

    &__icon {
      margin-right: 0.5rem;
    }
  }

  &__link {
    &__wrapper {
      width: 100%;
      padding: 0.65rem;
      margin-top: 0.5rem;
      display: flex;
      justify-content: space-between;
      align-items: center;
      border: 1px solid $primary-color;
      border-radius: 0.5rem;
    }

    &__text {
      text-align: left;
      font-size: 0.75rem;
    }
  }

  &__copied {
    display: flex;
    align-items: center;
    height: 1.5rem;
  }

  &__QRcode-wrapper {
    margin-top: 0.5rem;
    padding: 1rem;
    background-color: #fff;
  }
}
