@import '../../../assets/style/spacing.scss';

.loginCodeModal {
  text-align: center;
  padding-bottom: $space-lg;

  > button {
    margin-top: $space-lg;
  }
}
