@import '../../../assets/style/colors.scss';

.more {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &__options {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
  }

  &__footer {
    margin: 2rem;
    display: flex;
    align-items: center;
    justify-content: space-between;

    &-text {
      flex: 3;
    }

    &-icons {
      flex: 2;
      display: flex;
      justify-content: space-between;
    }
  }
}
