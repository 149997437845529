@import '../../../assets/style/colors.scss';
@import '../../../assets/style/spacing.scss';

.login {
  padding: $space-md;
  height: 100%;
  display: flex;
  flex-direction: column;

  & > .icon {
    margin: 0 0 auto 0;
  }

  &__body {
    flex-grow: 1;
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    padding: $space-md;

    & > h2 {
      text-align: center;
    }
  }

  &__sub {
    text-align: center;

    & > span {
      color: $primary-color;
      text-decoration: underline;
    }
  }

  &__input {
    margin-top: $space-lg;

    input:-webkit-autofill,
    input:-webkit-autofill:hover,
    input:-webkit-autofill:focus input:-webkit-autofill,
    textarea:-webkit-autofill,
    textarea:-webkit-autofill:hover textarea:-webkit-autofill:focus,
    select:-webkit-autofill,
    select:-webkit-autofill:hover,
    select:-webkit-autofill:focus {
      border-bottom: 2px solid #b5b5b5 !important;
      -webkit-text-fill-color: #b5b5b5 !important;
      -webkit-box-shadow: 0 0 0px 100px #182222 inset !important;
      transition: background-color 5000s ease-in-out 0s !important;
    }
  }

  &__button {
    width: fit-content;
    padding: 0 2rem;
  }

  &__error {
    color: $secondary;
  }

  &__noAcc {
    color: $primary-color;
  }

  &__forgotPw {
    color: $gray-light;
    text-decoration: underline;
    text-align: center;
  }
}
