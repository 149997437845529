@import '../../../../assets/style/spacing.scss';

.resetPwPopup {
  & h2 {
    margin: 1.5rem 0;
  }

  & .nextroundBtn {
    margin-top: $space-lg;
  }
}
